<template>
  <div class="formBox" :style="{height: type != 'workflow'?'calc(100vh - 251px)':''}">
    <el-main>
      <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="210px">
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="this.$route.query.type == 'edit' || this.$route.query.type == 'workflow' ? '境外项目人员信息编辑' : (this.$route.query.type == 'view' || dataForm.dataState == '3' ? '境外项目人员信息查看' : '境外项目人员信息新增')"
          @head-cancel="headCancel"
          @head-save="headSave"
          @head-saveBack="headSave(true)"
          @head-submit="headSubmit"
        ></head-layout>
        <el-row style="margin-top: 20px;">
<!--          <el-col :span="12">-->
<!--            <el-form-item label="项目编码：" prop="projectCode">-->
<!--              <el-input v-model="dataForm.projectCode" placeholder="请输入项目编码" disabled></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="12">
            <el-form-item label="统计日期：" prop="statDate">
              <el-date-picker
                v-model="dataForm.statDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :disabled="type == 'view' || type == 'workflow'"
                @change="chooseDate"
                placeholder="请选择统计日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-col :span="12">
          <el-form-item label="填报日期" prop="updateTime">
            <template slot="label">
              <span>填报日期:</span>
            </template>
            <el-date-picker
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="dataForm.updateTime"
              type="date"
              disabled="true"
              placeholder="保存后自动生成"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-row>
          <el-col :span="12">
            <el-form-item label="项目名称：" prop="projectName">
              <el-input v-model="dataForm.projectName" placeholder="由项目数据带出" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目状态:" prop="projectStatusName">
              <el-select
                v-model="dataForm.projectStatusName"
                placeholder=""
                :popper-append-to-body="false"
                :disabled="true"
              >
                <el-option
                  v-for="item in prjStateOption"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="业务类型：" prop="businessTypeName">
              <el-input v-model="dataForm.businessTypeName" placeholder="由项目数据带出" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国别：" prop="countryName">
              <el-input v-model="dataForm.countryName" placeholder="由项目数据带出" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="州/省：" prop="provinceName">
              <el-input v-model="dataForm.provinceName" placeholder="由项目数据带出" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总人数：" prop="totalCount">
              {{ totalCount }}
              <el-tooltip class="item" effect="dark" :content="dataForm.totalCountS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="中方员工总数：" prop="chineseCnt">
              {{ chineseCnt }}
              <el-tooltip class="item" effect="dark" :content="dataForm.chineseCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="外籍人员总人数：">
              {{ foreignerCnt }}
              <el-tooltip class="item" effect="dark" :content="dataForm.foreignerCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <head-layout
          head-title="人员信息"
          style="margin-bottom: 12px"
        ></head-layout>
        <el-row>
          <el-col :span="12">
            <el-form-item label="在册中方职工数：" prop="regeditedChineseCnt">
              <el-input v-model="dataForm.regeditedChineseCnt" placeholder="请输入在册中方职工数" maxlength="6"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
              <el-tooltip class="item" effect="dark" :content="dataForm.regeditedChineseCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="在册外籍职工数：" prop="regularForeignerCnt">
              <el-input v-model="dataForm.regularForeignerCnt" placeholder="请输入在册外籍职工数" maxlength="6"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
              <el-tooltip class="item" effect="dark" :content="dataForm.regularForeignerCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="公司员工总数：" prop="chineseCnt">
              {{ companyCnt }}
              <el-tooltip class="item" effect="dark" :content="dataForm.companyCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="内部人员增减说明：" prop="remark1">
              <el-input v-model="dataForm.remark1" type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                        placeholder="请输入内部人员增减说明" maxlength="200" :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
              <i class="el-icon-refresh iconLine" @click="remark1"></i>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="borderLine"></div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="分包商（含劳务）人数：" prop="subcontractorChineseCnt">
              <el-input v-model="dataForm.subcontractorChineseCnt" placeholder="请输入分包商人数" maxlength="6"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
              <el-tooltip class="item" effect="dark" :content="dataForm.subcontractorChineseCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳务公司派遣中方员工数：" prop="dispatchedChineseCnt">
              <el-input v-model="dataForm.dispatchedChineseCnt" placeholder="请输入劳务公司派遣中方员工数" maxlength="6"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
              <el-tooltip class="item" effect="dark" :content="dataForm.dispatchedChineseCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="第三方中方技术服务人员数：" prop="thirdPartyChineseCnt">
              <el-input v-model="dataForm.thirdPartyChineseCnt" placeholder="请输入第三方中方技术服务人员数"
                        maxlength="6"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
              <el-tooltip class="item" effect="dark" :content="dataForm.thirdPartyChineseCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他中方人员数：" prop="otherChineseCnt">
              {{ otherChineseCnt }}
              <el-tooltip class="item" effect="dark" :content="dataForm.otherChineseCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="外部人员增减说明：" prop="remark2">
              <el-input v-model="dataForm.remark2" type="textarea" :autosize="{ minRows: 4, maxRows: 4}"
                        placeholder="请输入外部人员增减说明" maxlength="200" :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4' || type == ''"></el-input>
              <i class="el-icon-refresh iconLine" @click="remark2"></i>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="borderLine"></div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="其他外籍人员数：" prop="otherForeignerCnt">
              <el-input v-model="dataForm.otherForeignerCnt" placeholder="请输入其他外籍人员数" maxlength="6"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
              <el-tooltip class="item" effect="dark" :content="dataForm.otherForeignerCntS" placement="top">
                <i class="el-icon-warning-outline iconLine"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <head-layout
          head-title="现场信息"
          style="margin-bottom: 12px"
        ></head-layout>
        <el-row>
          <el-col :span="12">
            <el-form-item label="现场负责人姓名：" prop="onsiteDirectorName">
              <el-input v-model="dataForm.onsiteDirectorName" placeholder="请输入现场负责人姓名" maxlength="30"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现场负责人职务：" prop="onsiteDirectorPost">
              <el-input v-model="dataForm.onsiteDirectorPost" placeholder="请输入现场负责人职务" maxlength="30"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="现场负责人联系方式：" prop="onsiteDirectorContact">
              <el-input v-model="dataForm.onsiteDirectorContact" placeholder="请输入现场负责人联系方式" maxlength="11"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现场负责人卫星电话数量：" prop="onsiteDirectorCellphone">
              <el-input v-model="dataForm.onsiteDirectorCellphone" placeholder="请输入现场负责人卫星电话数量"
                        maxlength="6"
                        :show-word-limit="true"
                        :readonly="type == 'view' || dataForm.dataState == '2' || dataForm.dataState == '4'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </div>
</template>
<script>

import GridLayout from "@/views/components/layout/grid-layout.vue";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import {getDetail, submit} from "@/api/foreignReport/domesticReport";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import {getDictionaryCode} from "@/api/system/dictbiz";

export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  mixins: [exForm, draft],
  data() {
    return {
      type: "",
      id: "",
      processTemplateKey: "fill_report_overseas",
      formProcess: {
        id: '',
        processId: '',
        deptId: '',
        paperName: '',
      },
      rules: {
        statDate: [
          {required: true, message: '请选择统计日期', trigger: 'change'}
        ],
        regeditedChineseCnt: [
          {required: true, message: '请输入在册中方职工数', trigger: 'blur'},
          {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
        ],
        dispatchedChineseCnt: [
          {required: true, message: '请输入劳务公司派遣中方员工数', trigger: 'blur'},
          {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
        ],
        thirdPartyChineseCnt: [
          {required: true, message: '请输入第三方中方技术服务人员数', trigger: 'blur'},
          {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
        ],
        subcontractorChineseCnt: [
          {required: true, message: '请输入分包商人数', trigger: 'blur'},
          {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
        ],
        regularForeignerCnt: [
          {required: true, message: '请输入在册外籍职工数', trigger: 'blur'},
          {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
        ],
        otherForeignerCnt: [
          {required: true, message: '请输入其他外籍人员数', trigger: 'blur'},
          {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
        ],
        onsiteDirectorContact: [
          {pattern: /\b(?:\d{3}-\d{8}|\d{4}-\d{7}|\d{11})\b/, message: "手机号码格式错误"},
        ],
        onsiteDirectorCellphone: [
          {pattern: /^[0-9]\d*$/, message: "请输入正整数"},
        ],
      },
      prjStateOption: [],
      endData: "", //上次时间
      startData: "", // 这次时间
      personnel: "",//外部人员
      dataForm: {
        projectCode: "",
        projectName: "",
        projectArea: "2",
        provinceName: "",
        countryName: "",
        businessTypeName: "",
        status: "",
        orgCode: "",
        totalCount: "",
        chineseCnt: "",
        regeditedChineseCnt: "",
        dispatchedChineseCnt: "",
        thirdPartyChineseCnt: "",
        subcontractorChineseCnt: "",
        otherChineseCnt: "",
        foreignerCnt: "",
        regularForeignerCnt: "",
        otherForeignerCnt: "",
        companyCnt: "",
        remark1: "",
        remark2: "",
        onsiteDirectorName: "",
        onsiteDirectorPost: "",
        onsiteDirectorContact: "",
        onsiteDirectorCellphone: "",
        projectProgress: "",
        statDate: "",
        dataState: "",
        dateSource: "1",
        createUserName: "",
        updateUserName: "",
        totalCountS: "",
        chineseCntS: "",
        regeditedChineseCntS: "",
        dispatchedChineseCntS: "",
        thirdPartyChineseCntS: "",
        subcontractorChineseCntS: "",
        otherChineseCntS: "",
        foreignerCntS: "",
        regularForeignerCntS: "",
        otherForeignerCntS: "",
        companyCntS: "",
        referenceStatistical: "",
      },
    }
  },
  computed: {
    headBtnOptions() {
      let that = this
      let buttonBtn = [];
      if ((this.type == 'workflow' && this.dataForm.dataState == 3) && that.type != 'view' && that.dataForm.dataState != '2') {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
          });
      }
      if (that.type != 'view' && that.dataForm.dataState == '1') {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.submitBtn'),
            emit: "head-submit",
            type: "button",
            icon: "",
          });
      }

      if (that.type != 'workflow') {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.celBtn'),
            emit: "head-cancel",
            type: "button",
            icon: "",
          });
      }
      return buttonBtn;
    },
    chineseCnt() {
      let number = 0;
      let regeditedChineseCnt = this.dataForm.regeditedChineseCnt && this.dataForm.regeditedChineseCnt.toString().replace(/\D/g, '');
      let dispatchedChineseCnt = this.dataForm.dispatchedChineseCnt && this.dataForm.dispatchedChineseCnt.toString().replace(/\D/g, '');
      let thirdPartyChineseCnt = this.dataForm.thirdPartyChineseCnt && this.dataForm.thirdPartyChineseCnt.toString().replace(/\D/g, '');
      let subcontractorChineseCnt = this.dataForm.subcontractorChineseCnt && this.dataForm.subcontractorChineseCnt.toString().replace(/\D/g, '');
      if (regeditedChineseCnt || dispatchedChineseCnt || thirdPartyChineseCnt || subcontractorChineseCnt) {
        number = (regeditedChineseCnt != undefined ? Number(regeditedChineseCnt) : 0)
          + (dispatchedChineseCnt != undefined ? Number(dispatchedChineseCnt) : 0)
          + (thirdPartyChineseCnt != undefined ? Number(thirdPartyChineseCnt) : 0)
          + (subcontractorChineseCnt != undefined ? Number(subcontractorChineseCnt) : 0)
      } else {
        number = '';
      }
      this.dataForm.chineseCnt = number;
      return number;
    },
    foreignerCnt() {
      let number = 0;
      let otherForeignerCnt = this.dataForm.otherForeignerCnt && this.dataForm.otherForeignerCnt.toString().replace(/\D/g, '');
      let regularForeignerCnt = this.dataForm.regularForeignerCnt && this.dataForm.regularForeignerCnt.toString().replace(/\D/g, '');
      if (otherForeignerCnt || regularForeignerCnt) {
        number = (otherForeignerCnt != undefined ? Number(otherForeignerCnt) : 0) + (regularForeignerCnt != undefined ? Number(regularForeignerCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.foreignerCnt = number;
      return number;
    },
    totalCount() {
      let number = 0;
      let chineseCnt = this.chineseCnt && this.chineseCnt.toString().replace(/\D/g, '');
      let foreignerCnt = this.foreignerCnt && this.foreignerCnt.toString().replace(/\D/g, '');
      if (chineseCnt || foreignerCnt) {
        number = (chineseCnt != undefined ? Number(chineseCnt) : 0)
          + (foreignerCnt != undefined ? Number(foreignerCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.totalCount = number;
      return number;
    },
    otherChineseCnt() {
      let that = this;
      let number = 0;
      let subcontractorChineseCnt = that.dataForm.subcontractorChineseCnt && that.dataForm.subcontractorChineseCnt.toString().replace(/\D/g, '');
      let dispatchedChineseCnt = that.dataForm.dispatchedChineseCnt && that.dataForm.dispatchedChineseCnt.toString().replace(/\D/g, '');
      let thirdPartyChineseCnt = that.dataForm.thirdPartyChineseCnt && that.dataForm.thirdPartyChineseCnt.toString().replace(/\D/g, '');
      if (subcontractorChineseCnt || dispatchedChineseCnt || thirdPartyChineseCnt) {
        number = (subcontractorChineseCnt != undefined ? Number(subcontractorChineseCnt) : 0)
          + (dispatchedChineseCnt != undefined ? Number(dispatchedChineseCnt) : 0)
          + (thirdPartyChineseCnt != undefined ? Number(thirdPartyChineseCnt) : 0);
      } else {
        number = '';
      }
      that.dataForm.otherChineseCnt = number;
      return number;
    },
    companyCnt() {
      let that = this;
      let number = 0;
      let regeditedChineseCnt = that.dataForm.regeditedChineseCnt && that.dataForm.regeditedChineseCnt.toString().replace(/\D/g, '');
      let regularForeignerCnt = that.dataForm.regularForeignerCnt && that.dataForm.regularForeignerCnt.toString().replace(/\D/g, '');
      if (regeditedChineseCnt || regularForeignerCnt) {
        number = (regeditedChineseCnt != undefined ? Number(regeditedChineseCnt) : 0)
          + (regularForeignerCnt != undefined ? Number(regularForeignerCnt) : 0);
      } else {
        number = '';
      }
      this.dataForm.companyCnt = number;
      return number;
    },
  },
  methods: {
    chooseDate(e) {
      if (this.dataForm.referenceStatistical) {
        if (this.startData) {
          this.endData = e;
          if (this.startData == this.endData) {
            this.getByIdData();
          } else {
            this.dataForm.remark1 = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
            this.dataForm.remark2 = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
            this.personnel = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
          }
        }
      }

    },
    getDictory() {
      getDictionaryCode('prj_state').then((res) => {
        this.prjStateOption = res.data.data;
      })
    },
    getByIdData() {
      getDetail(this.dataForm).then(res => {
        if (res.data.code === 200) {
          this.dataForm = res.data.data;
          this.startData = this.dataForm.statDate
          this.endData = this.dataForm.statDate
        }
      })
    },
    remark1() {
      if (this.dataForm.referenceStatistical) {
        if (this.startData == this.endData) {
          this.personnel = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
          let regedited = this.dataForm.regeditedChineseCnt - this.dataForm.regeditedChineseCntS
          let regular = this.dataForm.regularForeignerCnt - this.dataForm.regularForeignerCntS
          this.dataForm.remark1 = this.personnel + (regedited == 0 ? "" : (regedited > 0 ? "增加" : "减少") + Math.abs(regedited) + "名在册中方职工.") + (regular == 0 ? "" : (regular > 0 ? "增加" : "减少") + Math.abs(regular) + "名在册外籍职工.")
        } else {
          let regedited = this.dataForm.regeditedChineseCnt - this.dataForm.regeditedChineseCntS
          let regular = this.dataForm.regularForeignerCnt - this.dataForm.regularForeignerCntS
          this.dataForm.remark1 = this.personnel + (regedited == 0 ? "" : (regedited > 0 ? "增加" : "减少") + Math.abs(regedited) + "名在册中方职工.") + (regular == 0 ? "" : (regular > 0 ? "增加" : "减少") + Math.abs(regular) + "名在册外籍职工.")
        }
      } else {
        this.$message.warning("参考数据不是已审核通过数据,需手动填写!")
      }
    },
    remark2() {
      if (this.dataForm.referenceStatistical) {
        if (this.startData == this.endData) {
          this.personnel = this.dataForm.referenceStatistical + "~" + this.endData + "期间：";
          let subcontractor = this.dataForm.subcontractorChineseCnt - this.dataForm.subcontractorChineseCntS
          let dispatched = this.dataForm.dispatchedChineseCnt - this.dataForm.dispatchedChineseCntS
          let third = this.dataForm.thirdPartyChineseCnt - this.dataForm.thirdPartyChineseCntS
          this.dataForm.remark2 = this.personnel + (subcontractor == 0 ? "" : (subcontractor > 0 ? "增加" : "减少") + Math.abs(subcontractor) + "名分包商人数.")
            + (dispatched == 0 ? "" : (dispatched > 0 ? "增加" : "减少") + Math.abs(dispatched) + "名劳务公司派遣中方员工.")
            + (third == 0 ? "" : (third > 0 ? "增加" : "减少") + Math.abs(third) + "名第三方中方技术服务人员.")
        } else {
          let subcontractor = this.dataForm.subcontractorChineseCnt - this.dataForm.subcontractorChineseCntS
          let dispatched = this.dataForm.dispatchedChineseCnt - this.dataForm.dispatchedChineseCntS
          let third = this.dataForm.thirdPartyChineseCnt - this.dataForm.thirdPartyChineseCntS
          this.dataForm.remark2 = this.personnel + (subcontractor == 0 ? "" : (subcontractor > 0 ? "增加" : "减少") + Math.abs(subcontractor) + "名分包商人数.")
            + (dispatched == 0 ? "" : (dispatched > 0 ? "增加" : "减少") + Math.abs(dispatched) + "名劳务公司派遣中方员工.")
            + (third == 0 ? "" : (third > 0 ? "增加" : "减少") + Math.abs(third) + "名第三方中方技术服务人员.")
        }
      } else {
        this.$message.warning("参考数据不是已审核通过数据,需手动填写!")
      }
    },
    //提交
    headSubmit() {
      if (this.dataForm.statDate) {
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            if (this.dataForm.statDate) {
              this.$loading();
              if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
                this.dataForm.dataState = '1';
              }
              submit(this.dataForm, this.type).then(res => {
                if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                  this.dataForm = res.data.data;
                  this.type = 'edit';
                  this.$confirm("确定将提交审核?", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  })
                    .then(() => {
                      this.$loading();
                      let dataForm = this.dataForm
                      this.rpTaskAttributeSubmit(dataForm);
                    }).catch(() => {
                  })
                    .finally(() => {
                      this.$loading().close();
                    });
                } else {
                  this.$message.warning(res.data.data);
                }
              }).finally(() => {
                this.$loading().close();
              })
            } else {
              this.$message.warning("统计日期为空");
            }
          }
        })
      } else {
        this.$message.warning("统计日期为空");
      }
    },
    rpTaskAttributeSubmit(data) {
      if (!this.dataForm.id) {
        this.dataForm.createUserName = this.userInfo.real_name
      }
      this.dataForm.updateUserName = this.userInfo.real_name
      this.dataForm.projectArea = 2
      this.formProcess.id = data.id;
      this.formProcess.paperName = this.dataForm.paperName;
      this.handleStartProcess5(true,true).then((res) => {
        data.processInsId = res.data.data;
        data.dataState = '1'; // 设置状态为新增生成 审核中
        submit(data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    //提交
    headSave() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.dataForm.statDate) {
            this.$loading();
            if (this.dataForm.dataState != '4' && this.dataForm.dataState != '3') {
              this.dataForm.dataState = '1';
            }
            this.dataForm.processInsId = null
            submit(this.dataForm, this.type).then(res => {
              if (res.data.code === 200 && res.data.data != '数据已存在不能重复填报!') {
                this.dataForm = res.data.data;
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.warning(res.data.data);
              }
            })
              .finally(() => {
                this.$loading().close();
              })
          } else {
            this.$message.warning("统计日期为空");
          }
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
  created() {
    let type = this.$route.query.type;
    this.type = type;
  },

  mounted() {
    this.getProcessId();
    this.getDictory();
    if (this.type === 'workflow') {
      this.dataForm.id = this.$route.query.id
      this.getByIdData();
    } else {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.orgCode = this.$route.query.orgCode;
      if (row.id != -1) {
        this.dataForm.projectCode = row.projectCode
        this.dataForm.projectName = row.projectName
        this.dataForm.businessTypeName = row.businessTypeName
        this.dataForm.countryName = row.countryName
        this.dataForm.provinceName = row.provinceName
        this.dataForm.id = row.id;
        this.getByIdData();
      } else {
        this.dataForm.projectCode = row.projectCode
        this.dataForm.projectName = row.projectName
        this.dataForm.businessTypeName = row.businessTypeName
        this.dataForm.countryName = row.countryName
        this.dataForm.provinceName = row.provinceName
      }
    }

  },
}
</script>
<style scoped lang="scss">
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-top: 1px solid rgb(204, 204, 204);
}

.iconLine {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}

::v-deep .el-form-item__content {
  display: flex;
}

.borderLine {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}

.formBox {
  //height: calc(100vh - 251px);
  overflow: auto;
  overflow-x: hidden;
}
</style>
